<template>
  <v-btn :disabled="loading || !selectedBudgets.length" color="success" depressed size="small" @click="getPDF()"
    :loading="loading">
    <v-icon left>mdi-printer</v-icon>
    Indicador Operadora
  </v-btn>
  
</template>
<script>
import api from "@/http";
import jsPDF from "jspdf";
import "jspdf-autotable";
// import moment from "moment";
import { mapGetters } from 'vuex';
import UtilsPDF from '../../../service/utilsPDF';
import logo from "../../../assets/logo.png";
import ona from "../../../assets/ONA_Nivel_2.png";
import gptw from "../../../assets/GPTW_2023-2024.png";


export default {
  name: "IndicadorOperadora",
  data() {
    return {
      loading: false,
    };
  },
  props: {
    selectedBudgets: {
      type: Array,
      default: () => []
    },
  },
  methods: {
    async getPDF() {
      this.loading = true;
      this.$toast.success(`O documento está sendo preparado. Isso pode levar alguns instantes. Aguarde, por favor.`);
      
      try {
        this.getIndicador(); // Carrega os dados do indicador
        const doc = this.generatePDF();
        const indicador = this.indicador[0];
        const operadoranome = indicador.operadora_nome || "Operadora não informada";

        // Salva o PDF diretamente após a geração
        const filename = `Relatorio_Indicador_${operadoranome}.pdf`;
        doc.save(filename); // Salva o PDF no navegador

        this.loading = false;
      } catch (error) {
        this.$toast.error("Erro ao carregar os dados:", error);
        this.loading = false;
      }
    },
    generatePDF() {
      const doc = new jsPDF({ compress: true });
      this.drawHeader(doc);

      // Incluir as informações básicas da operadora
      const indicador = this.indicador[0];
      const operadoranome = indicador.operadora_nome || "Operadora não informada";
      const currentDate = new Date();  
      const mes = currentDate.getMonth(); 
      const ano = currentDate.getFullYear(); 

      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");
      doc.text(`Relatório da Operadora: ${operadoranome}`, 10, 70);
      doc.setFont("helvetica", "normal");
      doc.text(`Analise do mês ${mes} / ${ano}`, 10, 80);

       // Gerar os dados de quantidade por modalidade
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text("Resumo por Modalidade", 15, 90); // Ajustar a posição à esquerda
      doc.text("Resumo por Faixa Etária", 82, 90); // Ajustar a posição à esquerda

      // Criar a tabela com as modalidades
      const modalidades = {};
      this.indicador.forEach(item => {
        modalidades[item.modalidade] = (modalidades[item.modalidade] || 0) + 1;
      });

      const rows = Object.keys(modalidades).map(key => [key, modalidades[key]]);
      // Calcula o total de pacientes somando os valores das modalidades
      const total = Object.values(modalidades).reduce((sum, value) => sum + value, 0);

      // Função para calcular a idade com base na data de nascimento
      function calcularIdade(dataNascimento) {
        const nascimento = new Date(dataNascimento);
        const hoje = new Date();
        let idade = hoje.getFullYear() - nascimento.getFullYear();
        const mes = hoje.getMonth() - nascimento.getMonth();
        if (mes < 0 || (mes === 0 && hoje.getDate() < nascimento.getDate())) {
          idade--;
        }
        return idade;
      }

      // Definir as faixas etárias
      const faixasEtarias = [
        { faixa: '0 - 10 anos', min: 0, max: 10 },
        { faixa: '11 - 30 anos', min: 11, max: 30 },
        { faixa: '31 - 50 anos', min: 31, max: 50 },
        { faixa: '51 - 60 anos', min: 51, max: 60 },
        { faixa: '61 - 70 anos', min: 61, max: 70 },
        { faixa: '71 - 90 anos', min: 71, max: 90 },
        { faixa: '91 anos ou mais', min: 91, max: 200 }
      ];

      // Distribuir os pacientes nas faixas etárias
      const faixaEtariaContagem = faixasEtarias.map(faixa => ({
        faixa: faixa.faixa,
        quantidade: this.indicador.filter(item => {
          const idade = calcularIdade(item.data_nascimento);
          return idade >= faixa.min && idade <= faixa.max;
        }).length
      }));

      // Desenhar a tabela QTD por Modalidade
      doc.autoTable({
        head: [['Modalidade', 'Quantidade']],
        body: rows,
        startY: 95, // Posição vertical ajustada
        tableWidth: 60, // Largura reduzida para 1/3 da página
        styles: {
          fontSize: 10, // Reduzir o tamanho da fonte
          // halign: 'center', // Centraliza o conteúdo da tabela
          valign: 'middle', // Alinha verticalmente no centro
          lineColor: [44, 62, 80], // Cor das bordas das células
          lineWidth: 0.1, // Espessura das bordas
        },
        headStyles: {
          fillColor: [52, 152, 219], // Cor de fundo do cabeçalho
          textColor: [255, 255, 255], // Cor do texto do cabeçalho
          fontStyle: 'bold',
          halign: 'center', // Centraliza o texto do cabeçalho
        },
        bodyStyles: {
          halign: 'center', // Centraliza o texto nas células do corpo
        },
        footStyles: {
          fillColor: [236, 240, 241], // Cor de fundo do rodapé
          textColor: [0, 0, 0], // Cor do texto do rodapé
          fontStyle: 'bold',
        },
        foot: [
          [{ content: 'Total', styles: { halign: 'right', fillColor: [236, 240, 241], fontStyle: 'bold' } }, 
          { content: total.toString(), styles: { halign: 'center', fillColor: [236, 240, 241] } }]
        ],
        margin: { left: 10 }, // Posiciona a tabela à esquerda
        });

      // Desenhar a tabela por faixa Etária
      doc.autoTable({
        head: [['Faixa Etária', 'Quantidade']],
        body: faixaEtariaContagem.map(item => [item.faixa, item.quantidade.toString()]),
        startY: 95, // Posição ajustada para o lado da outra tabela
        margin: 77, // Posicionar ao lado da tabela de modalidades
        tableWidth: 60,
        styles: {
          fontSize: 10,
          halign: 'center',
          valign: 'middle',
          lineColor: [44, 62, 80],
          lineWidth: 0.1,
        },
        headStyles: {
          fillColor: [52, 152, 219],
          textColor: [255, 255, 255],
          fontStyle: 'bold',
          halign: 'center',
        },
        bodyStyles: {
          halign: 'center',
        },
        footStyles: {
          fillColor: [236, 240, 241],
          textColor: [0, 0, 0],
          fontStyle: 'bold',
        }
      });

      UtilsPDF.addPageNumbers(doc);

      return doc; // Retorna o objeto doc
    },
  

    drawHeader(doc) {
      try {
        doc.addImage(logo, 10, 8, 28, 28);
        doc.addImage(ona, 160, 10, 15, 15);
        doc.addImage(gptw, 185, 10, 15, 15);
      } catch (error) {
        this.$toast.error("Falha ao adicionar a imagem ao PDF:", error);
      }

      doc.setFont("helvetica", "bold");
      doc.setFontSize(18);
      doc.text("SÃO LUÍS HOME CARE", 105, 22, { align: "center" });
      doc.setFontSize(16);
      doc.text("RELATÓRIO DE INDICADOR MENSAL", 105, 53, { align: "center" });
    },

    async download(dataurl, filename) {
      const httpsurl = !dataurl.includes("https") ? dataurl.replace("http", "https") : dataurl
      const res = await fetch(httpsurl)
      const buffer = await res.blob()
      const url = URL.createObjectURL(buffer)
      const link = document.createElement("a");
      link.download = filename;
      link.href = url;
      link.click();
    },
     async getIndicador() {
      try {
        const currentDate = new Date();  
        let mes = currentDate.getMonth() + 1; 
        let ano = currentDate.getFullYear(); 

        if (mes === 1) { 
          mes = 12; 
          ano = ano - 1; 
        } else {
          mes = mes - 1; 
        }
        const { data } = await api.get(`operadoras/indicadores-operadora/?operadora=${this.selectedBudgets[0].id}&mes=${mes}&ano=${ano}`);
        this.indicador = data;
        // console.log(this.indicador)
      } catch (error) {
        this.$toast.error("Erro ao buscar os dados dos Indicadores", error);
      }
    },
    lista() {
      return this.userData || {}
    },
  },
  computer:{ ...mapGetters(['userData']),},
  mounted(){
    this.lista();
    this.getIndicador()
  }
};
</script>
