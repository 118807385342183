<template>
  <v-row justify="center">
    <v-col>
      <v-data-table :loading="isLoading('get:operators')" loading-text="Carregando Informações...." :items="filteredData" :search="search" 
        :headers="headers"  :single-expand="singleExpand" :expanded.sync="expanded" show-expand class="fixed-header-table" fixed-header  height="700px" :items-per-page="-1" >
        <template v-slot:top>
          <v-row class="mt-2">
            <v-col cols="12">
              <v-row>
                <v-col cols="5">
                  <v-text-field dense outlined v-model="search" label="Pesquisar" class="mx-0" placeholder="Pesquise por ID, Operadora, A, etc..." append-icon="mdi-magnify"
                    clearable />
                </v-col>
                <v-col class="text-end">
                  <v-badge left bordered overlap :content="activeFilters.length">
                    <v-btn class="mr-4 elevation-0" text v-on:click="showFilters = !showFilters">
                      <v-icon left>
                        mdi-filter
                      </v-icon>
                      Filtros
                    </v-btn>
                  </v-badge>
                  <v-btn class="mr-4 elevation-0" text @click="collapseAll"> Recolher Linhas </v-btn>
                  <v-btn class="mr-4 elevation-0" text @click="atualizar">
                    <v-icon left>
                      mdi-reload
                    </v-icon>
                    atualizar
                  </v-btn>
                  <v-btn color="success" class="elevation-0" @click="dialog.create = true">
                    <v-icon left>
                      mdi-plus
                    </v-icon>
                    Novo
                  </v-btn>
                 <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="elevation-0 ml-5" @click="exportToExcel" v-bind="attrs" v-on="on" style="background-color: transparent;">
                      <v-icon color="green" size="28">mdi-file-excel</v-icon>
                    </v-btn>
                  </template>
                  <span>Exportar para Excel</span>
                </v-tooltip>
                </v-col>
                <div class="align-self-top py-4" left bottom v-if="(selectedBudgets.length > 0)  "> 
                  <v-menu eager offset-x left offset-y bottom v-model="menu">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class=" align-center mx-8 " color="green" size="30" v-bind="attrs" v-on="on">
                        mdi-printer
                      </v-icon>
                    </template>
                    <v-container class="container">
                      <template >
                        <div >
                          <IndicadorOperadora :selectedBudgets="selectedBudgets" ></IndicadorOperadora>
                        </div>
                      </template>
                    </v-container>
                  </v-menu>
                </div>
              </v-row>
              <v-expand-transition>
                <v-card outlined v-show="showFilters" dense>
                  <h4 class="ml-4 mt-4">Filtrar por</h4>
                  <v-row dense class="py-2 px-4">
                    <v-col cols="2">
                      <v-select hide-details :items="statusOptions" label="Status" item-text="label" item-value="value"
                        v-model="filters.is_active.value" outlined dense />
                    </v-col>
                  </v-row>
                  <v-card-actions>
                    <v-row dense>
                      <v-col class="text-end">
                        <v-btn color="error" text elevation="0" @click="clearFilters">
                          Limpar filtros
                        </v-btn>
                        <v-btn color="success" class="ml-2" elevation="0" @click="applyFilters">
                          Aplicar filtros
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-expand-transition>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.select_budget`]="{ item }">
            <div class="d-flex align-center justify-center">
              <v-checkbox :input-value="selectedBudgets.includes(item)" @change="handleCheckboxChange(item)"></v-checkbox>
            </div>
          </template>
        <template v-slot:[`item.ativo`]="{ item }">
          <v-chip class="ma-2" :color="item.ativo ? 'blue' : 'grey'">
            <span class="white--text">{{ item.ativo ? 'Ativo' : 'Inativo' }}</span>
          </v-chip>
        </template>
        <template v-slot:[`item.datacontrato`]="{ item }">
            <span v-if="item.datacontrato">
              {{ item.datacontrato | formatData }}
            </span>
          </template>
       <template v-slot:[`item.editar`]="{ item }">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click="onSelectRow(item, 'update')">
                        <v-icon color="green">mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <span>Clique para editar</span>
            </v-tooltip>
        </template>
        <template v-slot:[`item.cnpj`]="{ item }">
          {{ formatCnpj(item.cnpj) }}
        </template>
        <template v-slot:[`item.actions`]="">
          <v-icon>
            mdi-arrow-expand
          </v-icon>
        </template>

        <template v-slot:expanded-item="{ headers,item }">
          <td :colspan="headers.length" class="py-4 grey lighten-2  text-start">
            <OperadoraExpand :operadoraData="item"></OperadoraExpand>
          </td>
        </template>
      </v-data-table>
    </v-col>
    <v-dialog v-model="dialog.create">
      <v-card>
        <v-card-title class="blod_color">
          Nova operadora
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.create = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <BaseForm :currentItem="newItem" :loading="isLoading('post:operators')" :onSubmit="postOperator" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <FullscreenDialog v-model="dialog.update" scrollable>
      <v-card>
        <v-card-title class="blod_color">
          Editar operadora: {{ selectedRow.nome }} / ID: {{ selectedRow.id }}
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.update = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <BaseForm :currentItem="selectedRow" :loading="isLoading('put:operators')" :onSubmit="putOperator" />
          <v-divider class="my-4" />
          <v-tabs v-model="currentTab"> 
            <v-tab>Procedimentos</v-tab>
            <v-tab>Taxas</v-tab>
            <v-tab>Equipamento</v-tab>
            <v-tab>Pacotes</v-tab>
            <v-tab>Planos</v-tab>
            <v-tab>Documentos</v-tab>
          </v-tabs>
          <v-tabs-items v-model="currentTab">
            <v-tab-item>
              <ProcedureTable :currentItem="selectedRow" />
            </v-tab-item>
            <v-tab-item>
              <ProcedureTaxa :currentItem="selectedRow" />
            </v-tab-item>
            <v-tab-item>
              <ProcedureEquipamento :currentItem="selectedRow" />
            </v-tab-item>
            <v-tab-item>
              <ProcedurePacote :currentItem="selectedRow" />
            </v-tab-item>
            <v-tab-item>
              <PlansTable :currentItem="selectedRow" />
            </v-tab-item>
            <v-tab-item>
              <Documentos :operadora-id="selectedRow.id"/>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </FullscreenDialog>
  </v-row>
</template> 
<script>
import api from '../../http';
import UtilsFunc from '../../service/utilsFunc';
import FullscreenDialog from '../FullscreenDialog.vue';
import BaseForm from './componentes/operadoras/BaseForm.vue';
import ProcedureTable from './componentes/operadoras/ProceduresTable.vue';
import ProcedureTaxa from './componentes/operadoras/ProceduresTaxa.vue';
import ProcedureEquipamento from './componentes/operadoras/ProceduresEquipamento.vue';
import Documentos from './componentes/operadoras/Documentos.vue';
import PlansTable from './componentes/operadoras/PlansTable.vue';
import OperadoraExpand from './componentes/operadoras/OperadoraExpand.vue';
import ProcedurePacote from './componentes/operadoras/ProceduresPacote.vue';
import IndicadorOperadora from '../relatorios/indicadores/IndicadorOperadora.vue';
import './../../assets/css/styles.css';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';


const { withCRUDUtils } = UtilsFunc

export default {
  name: 'Operdaoras',
  components: {
    FullscreenDialog,
    BaseForm,
    ProcedureTable,
    PlansTable,
    Documentos,
    OperadoraExpand,
    ProcedureTaxa,
    ProcedureEquipamento,
    ProcedurePacote,
    IndicadorOperadora,
  },
  data: () => withCRUDUtils({
    loading: false,
    search: '',
    currentTab: 0,
    newItem: { ativo: 1 },
    showFilters: false,
    expanded: [],
    singleExpand: false,
    statusOptions: [
      { label: "Ativo", value: true },
      { label: "Inativo", value: false },
    ],
    filters: {
      is_active: { value: true, compareType: 'equal', prop: 'ativo' }
    },
    activeFilters: [],
    headers: [
      { text: "Gerar Indicador", align: "center", sortable: false, value: "select_budget" },
      { text: "Editar Operadora", sortable: true, align: "center", value: "editar" },
      { text: "Id", sortable: true, value: "id" },
      { text: "Operadora", sortable: true, value: "nome" },
      { text: "Razão social", sortable: true, value: "razao" },
      { text: "CNPJ", sortable: true, value: "cnpj",  width: 170 },
      { text: "ANS", sortable: true, value: "ans" },
      { text: "Código na operadora", sortable: true, value: "cod_hc_slz" },
      { text: "Dt Contrato / Último Reajuste", value: "datacontrato" },
    ],
    selectedBudgets: [],
    menu: false,
  }),
  methods: {
    async getOperators() {
      const LOADING_NAME = 'get:operators'
      this.setLoading(LOADING_NAME)
      try {
        const { data } = await api('operadoras/operadoras/')
        this.tableData = data
        this.applyFilters()
      } catch (error) {
        this.$errorHandler(error)
        this.$toast.error(`Desculpe. Apresentou algum erro. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async postOperator(fields) {
      const LOADING_NAME = 'post:operators'
      this.setLoading(LOADING_NAME)
      try {
        const formData = new FormData()
        Object.entries(fields).forEach(([key, value]) => {
          if (value) formData.append(key, value)
        })
        await api.post("operadoras/operadoras/", formData)
        await this.getOperators()
        this.dialog.create = false
        this.$toast.success("Operadora inserida com sucesso!")
      } catch (error) {
        this.$errorHandler(error)
        this.$toast.error(`Desculpe. Apresentou algum erro. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async putOperator(fields) {
      const LOADING_NAME = 'put:operators'
      this.setLoading(LOADING_NAME)
      const formData = new FormData()
      Object.entries(fields).forEach(([key, value]) => {
        if (key === 'logo' && typeof value === 'string' || !value) return
        formData.append(key, value)
      })
      formData.append('ativar', fields.ativo)
      try {
        await api.put(`operadoras/operadora/${fields.id}/`, formData)
        await this.getOperators()
        this.$toast.success("Operadora atualizada com sucesso!")
      } catch (error) {
        this.$errorHandler(error)
        this.$toast.error(`Desculpe. Apresentou algum erro. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async applyFilters() {
      this.search = '';
      const filters = JSON.parse(JSON.stringify(this.filters))
      this.activeFilters = Object.values(filters).filter(filter => filter.value !== undefined);
    },
    clearFilters() {
      Object.keys(this.filters).forEach((prop) => this.filters[prop].value = '')
      this.filters = {
        is_active: { value: true, compareType: 'equal', prop: 'ativo' }
      }

      this.activeFilters = [{ value: true, compareType: 'equal', prop: 'ativo' }]
    },
    collapseAll() {
      this.expanded = [];
    },
     formatCnpj(cnpj) {
      if (!cnpj) return '';
      const cleaned = ('' + cnpj).replace(/\D/g, '');
      const match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/);
      if (match) {
        return `${match[1]}.${match[2]}.${match[3]}/${match[4]}-${match[5]}`;
      }
      return cnpj;
    },
    atualizar(){
      this.search = '';
      this.clearFilters();
      this.getOperators();

    },
    exportToExcel() {
      try {
        const worksheet = XLSX.utils.json_to_sheet(this.filteredData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Operadoras');

        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'operadoras.xlsx');
      } catch (error) {
        console.error('Erro ao exportar para Excel:', error);
      }
    },
  
     handleCheckboxChange(item) {
      if (!Array.isArray(this.selectedBudgets)) {
        this.selectedBudgets = []; // Garante que selectedBudgets é um array
      }

      if (this.selectedBudgets.includes(item)) {
        this.selectedBudgets = []; // Se o item já estiver selecionado, desmarque-o.
      } else {
        this.selectedBudgets = [item]; // Selecione o novo item, desmarcando todos os outros.
      }
    },
  },

  mounted() {
    this.getOperators()
  },
  computed: {
    filteredData() {
      const filterBy = UtilsFunc.createFilter(this.activeFilters)
      return this.tableData.filter(filterBy)
    },
  }
}
</script>  

<style scoped>
.table-container {
  max-height: 500px; /* Define a altura máxima para o contêiner da tabela */
  overflow-y: auto;  /* Permite a rolagem vertical */
}

.fixed-header-table thead th {
  background-color: white; /* Cor de fundo do cabeçalho para que ele permaneça visível */
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>